<template>
    <div>
        <van-cell-group title="通道">
            <van-cell :title="model.tongDaoSerialNumber + '(' + model.tongDaoPassageway + ')'" :value="model.tongDaoTemperature + '℃'" :label="'返回时间:' + model.tongDaoDataTime" value-class="font-red"></van-cell>
        </van-cell-group>
        <van-cell title="上限值" :value="model.shangXian + '℃'" value-class="font-red"></van-cell>
        <van-cell title="下限值" :value="model.xiaXian + '℃'" value-class="font-red"></van-cell>

    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);
export default {
    data() {
        return { model: {} };
    },
    mounted() {
        document.title = "上下限报警";
        let that = this;
        that.axios
            .post(
                "WXCW03_BJ/GetModel_BJRecord_SXX",
                {},
                {
                    params: {
                        sxxId: parseInt(that.$route.query.sxxId),
                        tongDaoId: parseInt(that.$route.query.tongDaoId),
                    },
                }
            )
            .then((response) => {
                that.model = response.data.data;
            });
    },
};
</script>

<style scoped>
.font-red {
    color: red;
    font-weight: bolder;
}
</style>